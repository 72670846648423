/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { searchPluginConfigContext } from "@whitespace/gatsby-plugin-search/src/contexts";
import SearchHits from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchHits";
import SearchProvider from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchProvider";
import SearchRangeInput from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchRangeInput";
import { startOfDay } from "date-fns";
import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Configure } from "react-instantsearch-hooks-web";

import EventSearchHit from "../../../gatsby-plugin-search/components/search-hits/EventSearchHit";

import CardsSearchHits from "./CardsSearchHits";
// import SearchDebug from "./SearchDebug";
import SearchBox from "./SearchBox";
import SearchHit from "./SearchHit";
import SearchMenuDropdown from "./SearchMenuDropdown";
import SearchPagination from "./SearchPagination";

ContentTypeArchive.propTypes = {
  contentType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default function ContentTypeArchive({ contentType }) {
  // const {
  //   indexUiState,
  //   setIndexUiState,
  //   uiState,
  //   setUiState,
  //   results,
  //   scopedResults,
  //   refresh,
  //   use,
  // } = useInstantSearch();

  const { i18n } = useTranslation();
  const searchPluginConfig = useContext(searchPluginConfigContext);
  const archiveConfig = useMemo(() => {
    let archiveConfig = searchPluginConfig?.archives?.[contentType.name] || {};
    return {
      searchBox: false,
      sortBy:
        contentType.name === "event"
          ? "start_date"
          : archiveConfig.searchBox
          ? "relevance"
          : "publish_date",
      ...archiveConfig,
      facets: {
        dates: true,
        ...archiveConfig?.facets,
      },
    };
  }, []);

  return (
    <SearchProvider routing={true} skipSearchIf={() => false}>
      {({ indexName }) => (
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
          `}
        >
          <Configure filters={`language:${i18n.language}`} />
          <Configure filters={`contentType.name:${contentType.name}`} />
          {contentType.name === "event" && (
            <Configure
              filters={`dates.numeric >= ${startOfDay(new Date()).valueOf()}`}
            />
          )}
          {archiveConfig.sortBy !== "relevance" && (
            <Configure sortBy={`${indexName}_${archiveConfig.sortBy}`} />
          )}
          {!!archiveConfig.searchBox && <SearchBox searchAsYouType={false} />}
          <div
            css={css`
              display: grid;
              gap: 1rem;
              @media (min-width: 768px) {
                grid-template-columns: repeat(4, 1fr);
              }
            `}
          >
            {!!archiveConfig.facets.dates && (
              <SearchRangeInput
                css={css`
                  @media (min-width: 768px) {
                    grid-column: span 2;
                  }
                `}
                attribute="dates.numeric"
              />
            )}
            {!!archiveConfig.facets.location && (
              <SearchMenuDropdown
                css={css`
                  @media (min-width: 768px) {
                    grid-column: span 1;
                  }
                `}
                attribute="location"
                placeholder="Plats"
              />
            )}
            {!!archiveConfig.facets.categories && (
              <SearchMenuDropdown
                css={css`
                  @media (min-width: 768px) {
                    grid-column: span 1;
                  }
                `}
                attribute="categories"
                placeholder="Kategori"
              />
            )}
          </div>
          {/* <SearchDebug /> */}
          {contentType.name === "event" ? (
            <CardsSearchHits hitComponent={EventSearchHit} />
          ) : (
            <SearchHits hitComponent={SearchHit} />
          )}
          <SearchPagination />
        </div>
      )}
    </SearchProvider>
  );
}
